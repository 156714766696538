export class User{
  constructor(user) {
    this.id = user.id
    this.username = user.username
    this.adminPassword = user.adminPassword
    this.documents = user.documents
    this.roles = user.roles
    this.reservations = user.reservations
    this.email = user.email
    this.firstname = user.firstname
    this.lastname = user.lastname
    this.birthDate = user.birthDate
    this.fiscalCode = user.fiscalCode
    this.status = user.status
    this.genre = user.genre
    this.ueStatus = user.ueStatus
    this.employment = user.employment
    this.whatsappContact = user.whatsappContact
    this.countryCode = user.countryCode
    this.uniLetter = user.uniLetter
    this.tellUs = user.tellUs
    this.isPrimary = user.isPrimary
    this.isGuest = user.isGuest
    this.addressStreet = user.addressStreet
    this.addressNumber = user.addressNumber
    this.addressZipCode = user.addressZipCode
    this.addressCity = user.addressCity
    this.addressCountry = user.addressCountry
    this.placeOfBirth = user.placeOfBirth
    this.docNumber = user.docNumber
    this.nationality = user.nationality
    this.nationalityCode = user.nationalityCode
    this.nationNumCode = user.nationNumCode
    this.registrationToken = user.registrationToken
    this.creationDate = user.creationDate
    this.lastModifiedDate = user.lastModifiedDate
    this.unitName = ''
    this.unitType = ''
    this.authenticationType = user.authenticationType
    if (user.reservations !== null) {
      user.reservations.forEach(element => {
        if (element.apartment !== null) {
          this.unitName === '' ? this.unitName = element.apartment.name : this.unitName = this.unitName + " - " + element.apartment.name
          this.unitType === '' ? this.unitType = element.apartment.accommodationTypeName : this.unitType = this.unitType + " - " + element.apartment.accommodationTypeName
        }
        if (element.room !== null) {
          this.unitName === '' ? this.unitName = element.room.name : this.unitName = this.unitName = this.unitName + " - " + element.room.name
          this.unitType === '' ? this.unitType = element.room.accommodationTypeName  : this.unitType = this.unitType + " - " + element.room.accommodationTypeName
        }
      });
    }
  }
}
