<template>
  <RFModal :title="`${user.firstname} ${user.lastname}`" :modalId="modalId" :loading="loading"
  @send="accept" ok-label="Accept" cancel-label="Reject" has-cancel>

    <b-row v-for="(userDataList, index) in userDataLists" :key="index">
      <b-col :cols="12 / userDataList.length" class="user-data" v-for="(userData, index) in userDataList" :key="`user-data-${index}`">
        <template v-if="userData.value">
          <span class="label">{{`${userData.label}: `}}</span>
          <span class="value" v-if="userData.type === 'date'">{{userData.value | formatDate}}</span>
          <span class="value" v-else>{{userData.value}}</span>
        </template>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col cols="12">
        <div class="admin-subtitle mt-5 mb-3">{{isEuropean ? $t('ID Document') : $t('Passport')}}</div>
      </b-col>
      <b-col :cols="document.docSide === docSides.FULL ? '12' : '6'" v-for="document in documentIds" :key="`document-${document.docSide}`">
        <object class="document w-100" :data="document.path | getAsset" type="application/pdf" v-if="document.format === 'PDF'"/>
        <img class="w-100" :src="document.path | getAsset" v-else>
      </b-col>
    </b-row>
    <b-row v-if="fiscalCodes.length">
      <b-col cols="12">
        <div class="admin-subtitle mt-5 mb-3">{{$t('Fiscal code')}}</div>
      </b-col>
      <b-col :cols="fiscalCode.docSide === docSides.FULL ? '12' : '6'" v-for="fiscalCode in fiscalCodes" :key="`document-${fiscalCode.docSide}`">
        <object class="document w-100" :data="fiscalCode.path | getAsset" type="application/pdf" v-if="fiscalCode.format === 'PDF'"/>
        <img class="w-100" :src="fiscalCode.path | getAsset" v-else>
      </b-col>
    </b-row>
    <b-row v-if="universityLetter">
      <b-col cols="12">
        <div class="admin-subtitle mt-5 mb-3">{{$t('University Letter')}}</div>
      </b-col>
      <b-col v-if="universityLetter">
        <object class="document w-100" :data="universityLetter.path | getAsset" type="application/pdf" v-if="universityLetter.format === 'PDF'"/>
        <img class="w-100" :src="universityLetter.path | getAsset" v-else>
      </b-col>
    </b-row>
  </RFModal>
</template>

<script>
import RFModal from '@/components/modals/RFModal'

import { adminService } from '@/services/admin'
import { docSides, docTypes, nationalityTypes, userStatus } from '@/enums'

export default {
  name: 'UserModal',
  components: {
    RFModal,
  },
  props: {
    user: null,
  },
  data() {
    return {
      docTypes,
      docSides,

      modalId: 'user-modal',
      loading: false,
    }
  },
  computed: {
    userDataLists() {
      return [
        [
          { label: 'Birth Date', value: this.user.birthDate, type: 'date' },
          { label: 'Nationality', value: this.user.nationality },
          { label: 'Genre', value: this.user.genre },
        ],
        [
          { label: 'Residence', value: this.user.addressCity },
          { label: 'Zip Code', value: this.user.addressZipCode },
          { label: 'Street', value: `${this.user.addressStreet}, ${this.user.addressNumber}` },
        ],
        [
          { label: 'Email', value: this.user.email },
          { label: 'ID Document/Passport', value: this.user.docNumber },
          { label: 'Fiscal Code', value: this.user.fiscalCode },
        ],
        [
          { label: 'Additional info', value: this.user.tellUs },
        ]
      ]
    },
    isEuropean() {
      return this.user.ueStatus === nationalityTypes.UE || this.user.ueStatus === nationalityTypes.ITALIAN
    },
    primaryID() {
      return this.isEuropean ? this.docTypes.ID_DOCUMENT : this.docTypes.PASSPORT
    },
    documentIds() {
      return this.user.documents.filter(document => document.docType === this.primaryID)
    },
    fiscalCodes() {
      return this.user.documents.filter(document => document.docType === this.docTypes.FISCALCODE)
    },
    universityLetter() {
      return this.user.documents.find(document => document.docType === this.docTypes.UNIVERSITY)
    }
  },
  methods: {
    async accept() {
      this.loading = true

      const user = await adminService.updateUserStatus(this.user.id, userStatus.VERIFIED)

      this.loading = false
      this.$root.$emit('bv::hide::modal', this.modalId)
      this.$toasted.success(this.$i18n.t('User successfully accepted!'))
      this.$emit('update', user)
    },
    async reject() {
      const user = await adminService.updateUserStatus(this.user.id, userStatus.REGISTERED)

      this.$root.$emit('bv::hide::modal', this.modalId)
      this.$toasted.success(this.$i18n.t('User successfully rejected!'))
      this.$emit('update', this.user)
    }
  }
}
</script>

<style lang="scss" scoped>
#user-modal {
  .user-title {
    @include font-style($montserrat, 'semibold', $font-20);
    text-transform: capitalize;
    color: $white;
    margin: 0;
  }
  .user-data {
    @include font-style($montserrat, 'medium', $font-16);
    color: $dark-blue;

    .label {
      @include font-style($montserrat-bold, 'bold', $font-16);
    }
  }
  .document {
    height: 80vh;
  }
}
</style>
