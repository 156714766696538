<template>
  <div class="users-to-verify">
    <UsersToVerifyTable title="Users To Verify" :list="filteredUserList" :fields="fields" :loading="loading"
                @update-list="update" @open-modal="openModal"/>
    <UserModal v-if="user" :user="user" @update="updateList"/>
  </div>
</template>

<script>
import UserModal from '@/components/admin/users/UserModal'

import {adminService} from '@/services/admin'
import { mapState } from 'vuex'
import {User} from "@/models/user";
import {isAdmin} from "@/helpers";
import UsersToVerifyTable from "@/components/admin/UsersToVerifyTable.vue";

export default {
  name: 'UsersToVerify',
  components: {
    UsersToVerifyTable,
    UserModal,
  },
  data() {
    return {
      userList: [],
      loading: false,
      filteredUserList: [],
      auxList: [],
    }
  },
  computed: {
    ...mapState({
      user: state => state.adminStore.user
    }),
    fields() {
      return [
        { key: 'firstname', label: 'Firstname' },
        { key: 'lastname', label: 'Lastname' },
        { key: 'fiscalCode', label: 'Fiscal Code' },
        { key: 'status', label: 'Status' },
        { key: 'birthDate', label: 'Birth Date', type: 'date' },
        { key: 'nationality', label: 'Nationality' },
        { key: 'actions', label: 'Actions', type: 'actions', actions: ['show'] },
      ]
    }
  },
  async beforeMount() {
    await this.update()
  },
  methods: {
    openModal(item) {
      this.$store.commit('adminStore/setUser', item)
      this.$root.$emit('bv::show::modal', 'user-modal')
    },
    async update() {
      this.loading = true
      this.filteredUserList = []
      this.userList = []
      this.auxList = await adminService.getUserToVerifyList()
      this.auxList.forEach(element => {
        let user = new User(element)
        this.userList.push(user)
        if(!isAdmin(user.roles)){
          this.filteredUserList.push(user)
        }
      });

      this.loading = false
    },
    updateList(updatedUser) {
      const index = this.userList.findIndex(user => user.id === updatedUser.id)
      this.userList.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.users-to-verify {
  .title {
    @include font-style($montserrat, 'semibold', $font-30);
    color: $dark-blue;
    text-transform: uppercase;
  }
  .refresh-list {
    @include font-style($montserrat, 'medium', $font-16);
    color: $dark-blue;
    cursor: pointer;
    margin-bottom: 20px;
    float: right;
  }
  .loading {
    @include font-style($montserrat, 'medium', $font-16);
    color: $dark-blue;
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
  .table-data {
    .status {
      border-radius: 20px;
      text-align: center;
      color: $white;

      &.new {
        background-color: $dark-blue;
      }
      &.registered {
        background-color: $green;
      }
      &.profile-complete {
        background-color: $dark-gray;
      }
      &.check-document {
        background-color: $orange;
      }
      &.verified {
        background-color: $success;
      }
    }
  }
}
</style>
